<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户账号：">
        <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户账号"></el-input>
      </el-form-item>
      <el-form-item label="店铺名称：">
        <el-input size="small" v-model="searchForm.store_name" placeholder="请输入店铺名称"></el-input>
      </el-form-item>
      <el-form-item label="商家姓名：">
        <el-input size="small" v-model="searchForm.merchant_name" placeholder="请输入商家姓名"></el-input>
      </el-form-item>
      <el-form-item label="商家电话：">
        <el-input size="small" type="number" v-model="searchForm.merchant_mobile" placeholder="请输入商家电话"></el-input>
      </el-form-item>
      <el-form-item label="店铺地址：">
        <el-cascader
          v-model="searchForm.area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
          <el-option label="全部" :value="0"></el-option>
          <el-option label="待审核" :value="-1"></el-option>
          <el-option label="已通过" :value="1"></el-option>
          <el-option label="已驳回" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="u_mobile" label="用户账号" width="110" align="center"></el-table-column>
      <el-table-column prop="store_name" label="店铺名称" width="100" align="center"></el-table-column>
      <el-table-column prop="classify_name" label="商家分类" width="100" align="center"></el-table-column>
      <el-table-column prop="merchant_name" label="商家姓名" align="center"></el-table-column>
      <el-table-column prop="merchant_mobile" label="商家电话" width="110" align="center"></el-table-column>
      <el-table-column prop="address" label="店铺地址" width="150" align="center"></el-table-column>
      <el-table-column prop="start_open_time" label="营业时间" width="145" align="center">
        <template v-slot="{ row }">
          {{ row.start_open_time }} - {{ row.end_open_time }}
        </template>
      </el-table-column>
      <el-table-column prop="distribution_mode" label="配送方式" align="center" width="200">
        <template v-slot="{ row }">
          <div v-for="(item, index) in row.distribution_mode" :key="index">
            <div v-if="item == 1">商家配送</div>
            <div v-if="item == 2">到店自提</div>
            <div v-if="item == 3">快递物流</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="distribution_community_name" label="配送小区" width="150" align="center">
        <template v-slot="{ row }">
          {{ row.distribution_community_name || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="store_logo" label="店铺logo" width="100" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="row.store_logo"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="store_picture" label="店铺照片" width="100" align="center">
        <template v-slot="{ row }">
          <div class="demo-image__preview">
            <el-image style="width: 100px; height: 50px" :src="row.store_picture[0]" :preview-src-list="row.store_picture"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="business_license" label="营业执照" width="100" align="center">
        <template v-slot="{ row }">
          <div class="demo-image__preview">
            <el-image style="width: 100px; height: 50px" :src="row.business_licenses[0]" :preview-src-list="row.business_licenses"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          <span>
            {{ row.status == -1 ? '待审核' : row.status == 1 ? '已通过' : '已驳回' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间" width="200" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="100">
        <template v-slot="{ row }">
          <div class="btn" v-if="row.status == -1">
            <el-button @click="review(row, 1)" type="text" size="small">通过</el-button>
            <el-button @click="review(row, 2)" type="text" size="small">驳回</el-button>
          </div>
          <el-button v-if="row.status == 2" @click="(remark = row.remark), (showRemark = !0)" type="text" size="small">驳回原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="showReview" width="600px">
      <el-form label-width="140px">
        <el-form-item label="请选择审核结果：" required="true">
          <el-radio-group v-model="status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="结算比例：" style="width: 500px" v-if="status == 1" required="true">
          <el-input v-model="coupon_rate" type="number" min="0.00" max="100.00" step="0.01" ><template slot="append">%</template></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" style="width: 500px" v-if="status == 1" required="true">
          <el-input v-model="store_password"></el-input>
        </el-form-item>
        <el-form-item label="请添加驳回原因：" v-if="status == 2" required="true">
          <el-input type="textarea" :rows="4" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReview = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmReview">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
      {{ remark }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showRemark = !1">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>
<script>

import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';

export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        u_mobile: '',
        store_name: '',
        merchant_name: '',
        merchant_mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        status: 0,
        start_time: '',
        end_time: '',
      },
      areaList: [],
      showReview: !1,
      showRemark: !1,
      id: '',
      status: 2,
      coupon_rate: 0,
      remark: '',
      store_password: '',
    };
  },
  created() {
    this.getList();
    this.getArea();
  },
  methods: {
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        u_mobile: '',
        store_name: '',
        merchant_name: '',
        merchant_mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        status: 0,
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    dealSearchData() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.area_id.length) {
        searchForm.province_id = searchForm.area_id[0];
        searchForm.city_id = searchForm.area_id[1];
        searchForm.area_id = searchForm.area_id[2];
      }
      return searchForm;
    },
    getList() {
      let searchForm = this.dealSearchData();
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.repair.store.auditList, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].distribution_mode = list[i].distribution_mode.split(',');
            list[i].store_picture = JSON.parse(list[i].store_picture);
            list[i].business_licenses = [list[i].business_license];
          }
          // console.log(list);
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    review(row, status) {
      this.status = status;
      this.id = row.id;
      this.remark = '';
      this.coupon_rate = 85;
      this.store_password = '';
      this.showReview = !0;
    },
    comfirmReview() {
      if (!this.coupon_rate && this.status == 1) return this.$message.warning('请添加结算比例');
      if (!this.store_password && this.status == 1) return this.$message.warning('请添加登录密码');
      if (!this.remark && this.status == 2) return this.$message.warning('请添加驳回原因');
      this.$axios
        .post(this.$api.repair.store.auditStatus, {
          id: this.id,
          status: this.status,
          coupon_rate: this.coupon_rate,
          remark: this.remark,
          store_password: this.store_password,
        })
        .then(res => {
          if (res.code == 0) {
            this.showReview = !1;
            this.$message.success('处理成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>